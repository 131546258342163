import React from 'react';
import './ProjectDetails.css';
import dacImage from '../assets/dacImage.png';
import espImage from '../assets/espImage.png';
import model1 from '../assets/model1.png';
import model2 from '../assets/model2.png';
import model3 from '../assets/model3.png';
import model4 from '../assets/model4.png';

const ProjectDetails = () => {
  return (
    <div className="project-details-container">
      <h1>CarbonGuardian Project</h1>

      <div className="section">
        <div className="image-left">
          <img src={espImage} alt="Electrostatic Precipitator" />
        </div>
        <div className="text-right">
          <h2>Electrostatic Precipitator (ESP)</h2>
          <p>The Electrostatic Precipitator (ESP) is a device used to remove particulate matter (PM2.5) from the air by charging particles and collecting them on plates. In the CarbonGuardian project, ESPs play a crucial role in improving air quality by targeting fine particulates that contribute to respiratory issues and environmental pollution. By efficiently filtering out these harmful particles, the ESP unit enhances the overall effectiveness of the air purification system.</p>
          <div className="button">
            <button><a href="https://en.wikipedia.org/wiki/Electrostatic_precipitator" className="button">Wikipedia</a></button>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="text-left">
          <h2>Direct Air Capture (DAC)</h2>
          <p> The Direct Air Capture (DAC) unit is an advanced technology designed to capture carbon dioxide (CO₂) directly from the atmosphere. In the context of the CarbonGuardian project, DAC serves as a critical component in combating climate change by removing CO₂—a major greenhouse gas—thereby contributing to a cleaner environment. Once captured, CO₂ can be stored or repurposed, turning atmospheric waste into a valuable resource. </p>
          <div className="button">
            <button><a href="https://en.wikipedia.org/wiki/Direct_air_capture" className="button">Wikipedia</a></button>
          </div>
        </div>
        <div className="image-right">
          <img src={dacImage} alt="Direct Air Capture (DAC) System" />
        </div>
      </div>

      <h2>Results</h2>
      <ul>
        <li>PM2.5 Removal Efficiency: ESP captures over 85% of particulate matter.</li>
        <li>CO2 Capture Efficiency: DAC captures approximately 70% of CO2 using a NaOH solution.</li>
        <li>Environmental Impact: The system significantly reduces air pollutants in the surrounding environment.</li>
        <li>Cost-Effectiveness: The entire system was developed with a budget of ₹3500.</li>
        <li>Portability: Compact design with wheels allows easy mobility.</li>
      </ul>

      <div className="project-images">
        <img src={model1} alt="Model Side 1" />
        <img src={model2} alt="Model Side 2" />
        <img src={model3} alt="Model Side 3" />
        <img src={model4} alt="Model Side 4" />
      </div>
    </div>
  );
};

export default ProjectDetails;
