import React from 'react';
import './Home.css';
import modelImage from '../assets/model1.png';
import highQualityVideo from '../assets/explanationVideo-high.mp4'; // High quality video path
import mediumQualityVideo from '../assets/explanationVideo-medium.mp4'; // Medium quality video path
import lowQualityVideo from '../assets/explanationVideo-low.mp4'; // Low quality video path

const Home = () => {
  return (
    <section className="home">
      <div className="intro">
        <h2>Welcome to CarbonGuardian</h2>
        <h3>Creating A Sustainable Tomorrow</h3>
        <br />
        <p>Mitigating pollution by reducing particulate matter and carbon dioxide in the atmosphere.</p>
        <div className="video-section">
          <h3>Watch the CarbonGuardian Model in Action</h3>
          <select id="video-quality" onChange={(e) => document.getElementById('video').src = e.target.value}>
            <option value={highQualityVideo}>High Quality</option>
            <option value={mediumQualityVideo}>Medium Quality</option>
            <option value={lowQualityVideo}>Low Quality</option>
          </select>
          <br /><br />
          <video id="video" controls>
            <source src={highQualityVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <br />
        <div className="button">
          <button><a href="/project-details">Explore Project</a></button>
        </div>
        <img src={modelImage} alt="CarbonGuardian Model" />
      </div>
    </section>
  );
};

export default Home;
