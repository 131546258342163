import React from 'react';
import './About.css';
import myPhoto from '../assets/myPhoto.png'; // Add your photo here

const About = () => {
  return (
    <div className="about-container">
      <h1>About Me</h1>
      <div className="about-content">
        <img src={myPhoto} alt="Raghav Agarwal" className="profile-photo" />
        <div className="about-details">
          <h2>Raghav Agarwal</h2>
          <p>
            Hi, I'm Raghav Agarwal, a student passionate about technology and environmental conservation. I'm currently working on the CarbonGuardian project, aimed at reducing air pollution using innovative methods like electrostatic precipitation and direct air capture.
          </p>
          <br></br>
          <p>
            Contact me for collaborations, suggestions, or if you want to learn more about my projects.
          </p>
          <h3>Contact Information</h3>
          <p>School: Maheshwari Public School, Ajmer(305004),Rajasthan</p>
          <p>Location: 574, Hanuman Gali, Pushkar, 305022</p>
          <p>Email: sanjaysaumya1234@gmail.com</p>
          <p>School Email: Raghav-10168-19@mpsajmer.onmicrosoft.com</p>
          <h3>Social Media</h3>
          <div className="social-links">
            <a href="https://www.linkedin.com/in/raghav-agarwal-pushkar/" target="_blank" rel="noreferrer">LinkedIn</a>
            <br></br>
            <a href="https://github.com/RAWENTERISLIVE" target="_blank" rel="noreferrer">GitHub</a>
            <br></br>
            <a href="https://www.raghav.studio" target="_blank" rel="noreferrer">Portfolio Website</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
