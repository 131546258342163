import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <nav>
        <Link to="/">Home</Link> | {' '}
        <Link to="/project-details">Project Details</Link> |{' '}
        <Link to="/brochure">Brochure & Video</Link> |{' '}
        <Link to="/about">About</Link> |{' '}
        
      </nav>
    </header>
  );
};

export default Header;
