import React, { useEffect } from 'react';
import brochureFile from '../assets/Brochure.pdf';
import explanationVideo from '../assets/explanationVideo-low.mp4';

const Brochure = () => {
  useEffect(() => {
    // Function to trigger file download
    const triggerDownload = (file, fileName) => {
      const link = document.createElement('a');
      link.href = file;
      link.download = fileName;
      link.click();
    };

    // Automatically trigger downloads for both the PDF and video
    triggerDownload(brochureFile, 'CarbonGuardian_Brochure.pdf');
    triggerDownload(explanationVideo, 'explanationVideo-low.mp4');
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h2 style={{ color: '#ffffff' }}>Your downloads should start automatically...</h2>
      <p>
        If the downloads don’t start,{' '}
        <a
          href={brochureFile}
          download="CarbonGuardian_Brochure.pdf"
          style={{
            color: '#000000', // Text color of the button
            backgroundColor: '#ffffff', // Button background color
            padding: '10px 20px',
            textDecoration: 'none',
            borderRadius: '5px',
            fontWeight: 'bold',
            display: 'inline-block',
            marginTop: '10px',
          }}
        >
          Click here to download the brochure
        </a>
        {' '}and{' '}
        <a
          href={explanationVideo}
          download="explanationVideo-low.mp4"
          style={{
            color: '#000000', // Text color of the button
            backgroundColor: '#ffffff', // Button background color
            padding: '10px 20px',
            textDecoration: 'none',
            borderRadius: '5px',
            fontWeight: 'bold',
            display: 'inline-block',
            marginTop: '10px',
          }}
        >
          Click here to download the video
        </a>.
      </p>
    </div>
  );
};

export default Brochure;
