import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import ProjectDetails from './pages/ProjectDetails';
import Brochure from './pages/Brochure';
import Header from './components/Header';
import Footer from './components/Footer';
import './styles.css';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/project-details" element={<ProjectDetails />} />
        <Route path="/brochure" element={<Brochure />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
